<template>
	<div>
		<CRow>
			<CCol class="text-right mb-3">
				<router-link
					:to="{ name: 'UserRoleCreate' }"
					class="btn btn-secondary"
				>
					<CIcon name="cil-plus" class="mr-2" />
					Create Group
				</router-link>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<form
					class="search-form"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						data-test-id="search-input"
						placeholder="Search by Group name"
					>
						<template #prepend-content>
							<CIcon name="cil-magnifying-glass" data-test-id="search-button" />
						</template>
					</CInput>
				</form>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<BaseTable
					:is-loading="list.isLoading"
					:fields="USER_ROLE_FIELDS"
					:items="dataTable"
					striped
					link-to="UserRoleEdit"
					class="table-custom"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{ $t("global.searchNotFound", { field: "users" }) }}
							</p>
						</div>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { cleanObject, cloneDeep } from '../assets/js/helpers';
import { USER_ROLE_FIELDS } from '../enums/roles';

export default {
	name: 'UserRole',
	data() {
		return {
			queryParams: {
				q: this.$route.query.q || null,
			},
		};
	},
	computed: {
		...mapState('roles', {
			list: 'list',
		}),
		...mapGetters({
			roles: 'roles/getRoles',
		}),
		USER_ROLE_FIELDS: () => USER_ROLE_FIELDS,
		dataTable() {
			return this.roles.map((role) => {
				return {
					id: role.id,
					name: role.name,
					description: role.description,
					total: role.users.length,
				};
			});
		},
	},
	async created() {
		await this.getRoleList(this.queryParams);
	},
	methods: {
		...mapActions({
			getRoleList: 'roles/getRoleList',
		}),
		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},
		handleSearch() {
			this.updateUrlParams();
		},
	},
};
</script>
